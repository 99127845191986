import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import {Link, graphql } from "gatsby"
import { Styled,Message,Badge } from 'theme-ui'
//this is Essays posts
function Blogs({ data }) {
  //decode title
  return (
    <Layout>
    <SEO title="blogs" />
    <h1>Essays</h1>
    {data.allWordpressPost.edges.map(({ node }) => (
      <div key={node.slug} >
        <Styled.a as={Link}  to={node.slug}>
        <h2>{node.title}</h2>
        </Styled.a>
        <Img key={node.featured_media.localFile.childImageSharp.resolutions.src} fluid={node.featured_media.localFile.childImageSharp.fluid} />
        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      </div>
    ))}
  </Layout>
  )
}

export default Blogs

export const query = graphql`
  query {
    allWordpressPost {
        edges {
          node {
            title
            excerpt
            date
            slug
            featured_media {
              localFile {
                  childImageSharp {
                    fluid(maxWidth:450, quality:100){
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                    resolutions(width:350, height: 500) {
                      ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                    }
                  }
              }
            }
          }
        }
      }
  }
`